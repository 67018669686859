import { ActionTypes } from "../constants/action-type";

const initialState = {
  selectedProducts: [],
  categoryName: "",
  categoryId: "",
  subCategoryId: "",
  filterAttribute: [],
  filters: [],
  noOfProduct: 30,
  sortBy: "createdTime desc",
  pageNo: 1,
  plating: "",
  colors: "",
  priceLow: "",
  priceUpper: "",
  searchText: "",
  isFilterApplied: false,
};

export const viewAllReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_INITIAL_FILTERS:
      return { ...state };
    case ActionTypes.SET_SELECTED_PRODUCTS: {
      return { ...state, selectedProducts: payload };
    }
    case ActionTypes.UPDATE_FILTERS_KEY_VALUE: {
      let newState = { ...state };
      newState.isFilterApplied = true;
      newState[payload.key] = payload.value;
      return newState;
    }
    case ActionTypes.APPLY_VIEW_ALL_FILTERS: {
      return { ...state, isFilterApplied: true };
    }
    case ActionTypes.RESET_VIEW_ALL_FILTERS: {
      return { ...state, filters: [], isFilterApplied: false };
    }
    default:
      return state;
  }
};
